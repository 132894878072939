import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { withStyles, WithStyles } from "@mui/styles";
import { styles } from "./confirm-modal.styles";
import { t } from "@lingui/macro";

export interface AddInvitationCodeModalProps extends WithStyles<typeof styles> {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  error?: string;
}

const AddInvitationCodeModal: FC<AddInvitationCodeModalProps> = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  error,
}: AddInvitationCodeModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <Stack>
                  <AlertTitle>{t`Processing error`}</AlertTitle>
                  <AlertDescription>{t`Please try again`}</AlertDescription>
                </Stack>
              </Alert>
            )}
            {description}
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              type={"button"}
              isLoading={isLoading}
              onClick={onConfirm}
            >
              {t`Confirm`}
            </Button>
            <Button variant="outline" onClick={onClose}>{t`Cancel`}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default withStyles(styles)(AddInvitationCodeModal);
