import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import {
  AuthStatusData,
  IAuthService,
} from "@shared/interfaces/auth-service.interface";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { LoginScreen } from "@ui/auth/login/login-screen";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { ILoginForm } from "@ui/auth/login/forms/login-form/login-form.validator";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import { AuthStatus } from "@shared/constants/auth";
import { IChangePasswordForm } from "@ui/auth/login/forms/change-password-form/change-password-form.validator";

export class LoginViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private _activeScreen: LoginScreen = LoginScreen.LOGIN_FORM;
  private _navigate: NavigateFunction;

  constructor() {
    appMakeObservable(this, {
      _activeScreen: appObservable,
    });
  }

  setNavigate(fn: NavigateFunction) {
    this._navigate = fn;
    return this;
  }

  get activeScreen() {
    return this._activeScreen;
  }

  async login({ email, password }: ILoginForm): Promise<AuthStatusData> {
    return await this.authService.login(email, password);
  }

  get mfaDelayMinutes() {
    return this.configService.mfaDelayMinutes;
  }

  async savePassword(password: string) {
    try {
      return await this.authService.handleNewPassword(password);
    } catch (ex: any) {
      throw ex.name === "NotAuthorizedException"
        ? "Invalid credentials"
        : "Something went wrong";
    }
  }

  handleSuccessLogin(data: AuthStatusData) {
    if (this.authService.isLoggedIn) {
      this._navigate(ROUTES.home);
    } else {
      switch (data.status) {
        case AuthStatus.passwordChangeRequired:
          this._activeScreen = LoginScreen.CHANGE_PASSWORD_FORM;
          break;
        default:
          break;
      }
    }
  }
}
