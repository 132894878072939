import { createStyles } from "@mui/styles";
import { theme } from "@chakra-ui/react";

export const styles = () =>
  createStyles({
    navLink: {},
    button: {
      "$active &": {
        color: theme.colors.cyan["700"],
      },
    },
    active: {},
  });
