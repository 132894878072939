import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React, { FC, KeyboardEvent, useRef } from "react";
import { withStyles, WithStyles } from "@mui/styles";
import { styles } from "./add-document-modal.styles";
import { useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import { t } from "@lingui/macro";
import {
  AddDocumentFormFields,
  AddDocumentFormFieldsResolver,
  IAddDocumentForm,
} from "@shared/components/add-document-modal/add-document-modal.validator";
import { CreateDocumentDto } from "@shared/types/documents/create-document.dto";

export interface AddDocumentModalProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: CreateDocumentDto) => void;
  isLoading: boolean;
  error?: string;
}

const AddDocumentModal: FC<AddDocumentModalProps> = ({
  isOpen,
  onClose,
  onSave,
  isLoading,
  error,
}: AddDocumentModalProps) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AddDocumentFormFields>({
    resolver: AddDocumentFormFieldsResolver,
  });

  const submitForm = (formData: IAddDocumentForm) => {
    onSave(formData);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form
          onSubmit={handleSubmit(submitForm)}
          onKeyDown={(e) => onKeyPress(e)}
        >
          <ModalHeader>{t`Create document`}</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pb={6}>
            {error && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <Stack>
                  <AlertTitle>{t`Processing error`}</AlertTitle>
                  <AlertDescription>{t`Please try again`}</AlertDescription>
                </Stack>
              </Alert>
            )}
            <FormControl isInvalid={!!errors.slug?.message}>
              <FormLabel htmlFor="slug">{t`Slug`}</FormLabel>
              <Input
                {...register(nameOf<IAddDocumentForm>("slug"))}
                id="slug"
                type="text"
                disabled={isLoading}
              />
              <FormErrorMessage>{errors.slug?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              type={"submit"}
              disabled={!isValid}
              isLoading={isLoading}
            >
              {t`Create`}
            </Button>
            <Button variant="outline" onClick={onClose}>{t`Cancel`}</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default withStyles(styles)(AddDocumentModal);
