import { BaseModel } from "@core/models/base-model";
import { DocumentVersionDto } from "@shared/models/documents/document-version.model";

export interface DocumentDetailsDto {
  id: string;
  typeId: string;
  typeName: string;
  slug: string;
  versions: Array<DocumentVersionDto>;
}

export class DocumentDetailsModel extends BaseModel<DocumentDetailsDto> {
  private id: string;
  private typeId: string;
  private typeName: string;
  private slug: string;
  private versions: Array<DocumentVersionDto>;

  constructor(data: DocumentDetailsDto) {
    super();

    this.update(data);
  }

  get asJson(): DocumentDetailsDto {
    return {
      id: this.id || "",
      typeId: this.typeId || "",
      typeName: this.typeName || "",
      slug: this.slug || "",
      versions: this.versions || [],
    };
  }
}
