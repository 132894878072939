import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IDocumentsService } from "@shared/interfaces/documents-service.interface";
import { DocumentDetailsModel } from "@shared/models/documents/document-details.model";

export class InfoDocumentsTableViewModel {
  private documentsService = appInject<IDocumentsService>(
    DI_TOKENS.documentsService,
  );

  createDataSource(id: string) {
    return this.documentsService.createInfoDocumentsVersionStore(id);
  }

  async getDocumentDetails(id: string): Promise<DocumentDetailsModel> {
    return this.documentsService.getInfoDocument({ id });
  }
}
