import {
  IObservableFactory,
  CreateObservableOptions,
  makeObservable,
} from "mobx";
export { observer as appObserver } from "mobx-react";
export { observable as appObservable, reaction as appReaction } from "mobx";

export const appMakeObservable = <T>(
  target: T,
  annotations: { [key: string]: IObservableFactory },
  options?: CreateObservableOptions,
) => makeObservable<any>(target, annotations, options);
