import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { IsNotEmpty, IsString, MinLength } from "class-validator";

export interface IAddInvitationCodeForm {
  code: string;
}

export class AddInvitationCodeFormFields implements IAddInvitationCodeForm {
  @IsString()
  @IsNotEmpty()
  @MinLength(4)
  code: string;
}

export const AddInvitationCodeFieldsResolver = classValidatorResolver(
  AddInvitationCodeFormFields,
);
