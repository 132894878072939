import React, { FC, ReactNode, useEffect } from "react";
import { styles } from "./document-version-form.styles";
import { WithStyles, withStyles } from "@mui/styles";
import { PageLayout } from "@shared/components/layouts/page-layout";
import {
  HtmlEditor,
  Item,
  MediaResizing,
  Toolbar,
} from "devextreme-react/html-editor";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import type { ValueChangedEvent } from "devextreme/ui/html_editor";
import { DocumentVersionDto } from "@shared/models/documents/document-version.model";
import {
  IVersionForm,
  VersionFormFields,
  VersionFormFieldsResolver,
} from "./document-version-form.validator";

export interface TermsDocumentsVersionsProps extends WithStyles<typeof styles> {
  title: string;
  description: string | ReactNode;
  isCreating: boolean;
  isLoading: boolean;
  onSubmit: (formData: IVersionForm) => void;
  error?: string;
  onClose: () => void;
  data?: DocumentVersionDto;
}

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

const TermsDocumentsVersions: FC<TermsDocumentsVersionsProps> = ({
  title,
  description,
  isCreating,
  isLoading,
  onSubmit,
  onClose,
  error,
  data,
}: TermsDocumentsVersionsProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<VersionFormFields>({
    resolver: VersionFormFieldsResolver,
  });

  useEffect(() => {
    setValue(nameOf<IVersionForm>("lastUpdatedDate"), new Date().toISOString());
  }, []);

  useEffect(() => {
    if (!isCreating) {
      setValue(nameOf<IVersionForm>("lang"), data?.lang || "");
      setValue(nameOf<IVersionForm>("title"), data?.title || "");
      setValue(nameOf<IVersionForm>("body"), data?.body || "");
    }
  }, []);

  const submitForm = (formData: IVersionForm) => {
    onSubmit(formData);
  };

  const onChangeHtmlEditor = (e: ValueChangedEvent) => {
    setValue(nameOf<IVersionForm>("body"), e.value);
  };

  return (
    <PageLayout title={title} description={description}>
      <form onSubmit={handleSubmit(submitForm)}>
        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <Stack>
              <AlertTitle>{t`Processing error`}</AlertTitle>
              <AlertDescription>{t`Please try again`}</AlertDescription>
            </Stack>
          </Alert>
        )}
        <FormControl
          isInvalid={!!errors.lang?.message}
          sx={{ margin: "10px 0px", height: "91px" }}
        >
          <FormLabel htmlFor="code">{t`Lang`}</FormLabel>
          <Input
            {...register(nameOf<IVersionForm>("lang"))}
            id="lang"
            type="text"
            disabled={isLoading || !isCreating}
          />
          <FormErrorMessage>{errors.lang?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!errors.title?.message}
          sx={{ height: "91px" }}
        >
          <FormLabel htmlFor="title">{t`Title`}</FormLabel>
          <Input
            {...register(nameOf<IVersionForm>("title"))}
            id="title"
            type="text"
            disabled={isLoading}
          />
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!errors.body?.message}
          sx={{
            padding: "10px 0px 40px 0px",
            height: "calc(100vh - 450px)",
          }}
        >
          <FormLabel htmlFor="body">{t`Text`}</FormLabel>
          <HtmlEditor
            isValid={!errors.body?.message}
            defaultValue={!isCreating ? data?.body || "" : ""}
            valueType="html"
            onValueChanged={onChangeHtmlEditor}
          >
            <Toolbar multiline={true}>
              <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" />
              <Item name="size" acceptedValues={sizeValues} />
              <Item name="font" acceptedValues={fontValues} />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="orderedList" />
              <Item name="bulletList" />
              <Item name="separator" />
              <Item name="header" acceptedValues={headerValues} />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="separator" />
              <Item name="link" />
              <Item name="image" />
              <Item name="separator" />
              <Item name="clear" />
              <Item name="codeBlock" />
              <Item name="blockquote" />
              <Item name="separator" />
            </Toolbar>
            <MediaResizing enabled={true} />
          </HtmlEditor>
          <FormErrorMessage>{errors.body?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!errors.body?.message}
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            mr={3}
            type={"submit"}
            disabled={!isValid}
            isLoading={isLoading}
          >
            {isCreating ? t`Create` : t`Save`}
          </Button>
          <Button variant="outline" onClick={onClose}>{t`Cancel`}</Button>
        </FormControl>
      </form>
    </PageLayout>
  );
};

export default withStyles(styles)(TermsDocumentsVersions);
