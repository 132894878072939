import React, { FC, useCallback, useState } from "react";
import { styles } from "./invitation-codes.styles";
import { WithStyles, withStyles } from "@mui/styles";
import { PageLayout } from "@shared/components/layouts/page-layout";
import { Button } from "@chakra-ui/react";
import { AddInvitationCodeModal } from "./components/add-invitation-code-modal";
import { useMutation, UseMutationResult } from "react-query";
import { IdDto } from "@shared/types/id.dto";
import { IAddInvitationCodeForm } from "@ui/private/invitation-codes/components/add-invitation-code-modal/add-invitation-code.validator";
import { InvitationCodesViewModel } from "./invitation-code.vm";
import { InvitationCodesTable } from "./components/invitation-codes-table";
import { t } from "@lingui/macro";
import { ConfirmModal } from "@shared/components/confirm-modal";
import { InviteCodeDto } from "@shared/models/invite-codes/invite-code.model";

export type InvitationCodesProps = WithStyles<typeof styles>;

const InvitationCodes: FC<InvitationCodesProps> = ({
  classes,
}: InvitationCodesProps) => {
  const $vm = React.useMemo(() => new InvitationCodesViewModel(), []);

  const [reloadGridData, setReloadGridData] = useState<(() => void) | null>(
    null,
  );

  const [serverError, setServerError] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<InviteCodeDto | null>(null);

  const createInvitationCodeMutation: UseMutationResult<
    IdDto,
    unknown,
    IAddInvitationCodeForm,
    unknown
  > = useMutation(
    (data: IAddInvitationCodeForm): Promise<IdDto> => $vm.createCode(data),
    {
      onSuccess: () => {
        setIsOpenAddInvitationCodeModal(false);
        if (reloadGridData) {
          reloadGridData();
        }
      },
      onError: () => {
        setServerError("Server Error");
      },
    },
  );

  const deleteInvitationCodeMutation: UseMutationResult<
    void,
    unknown,
    IdDto,
    unknown
  > = useMutation((data: IdDto): Promise<void> => $vm.deleteCode(data.id), {
    onSuccess: () => {
      setIsOpenConfirmModal(false);
      if (reloadGridData) {
        reloadGridData();
      }
    },
    onError: () => {
      setServerError("Server Error");
    },
  });

  const [isOpenAddInvitationCodeModal, setIsOpenAddInvitationCodeModal] =
    useState(false);

  const handleCloseAddInvitationModal = () => {
    setIsOpenAddInvitationCodeModal(false);
    setServerError("");
  };

  const handleSetReloadGridData = useCallback((reloadFunction: () => void) => {
    setReloadGridData(() => reloadFunction);
  }, []);

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
    setSelectedCode(null);
    setServerError("");
  };

  const handleClickDelete = useCallback((data: InviteCodeDto) => {
    setSelectedCode(data);
    setIsOpenConfirmModal(true);
  }, []);

  return (
    <PageLayout
      title={t`Invitation Codes`}
      description={t`This section is for managing invitation codes`}
      primaryButton={
        <Button
          variant="outline"
          onClick={() => {
            setIsOpenAddInvitationCodeModal(true);
          }}
        >{t`Create invitation code`}</Button>
      }
    >
      <AddInvitationCodeModal
        isOpen={isOpenAddInvitationCodeModal}
        onClose={handleCloseAddInvitationModal}
        onSave={(formData: IAddInvitationCodeForm) =>
          createInvitationCodeMutation.mutate(formData)
        }
        isLoading={createInvitationCodeMutation.isLoading}
        error={serverError}
      />
      <ConfirmModal
        title={t`Confirm deletion`}
        description={t`Do you really want to remove the invitation code ${selectedCode?.code} ?`}
        isOpen={isOpenConfirmModal}
        isLoading={deleteInvitationCodeMutation.isLoading}
        error={serverError}
        onConfirm={() =>
          deleteInvitationCodeMutation.mutate({ id: selectedCode?.id || "" })
        }
        onClose={handleCloseConfirmModal}
      />
      <InvitationCodesTable
        classes={classes}
        onReload={handleSetReloadGridData}
        onDelete={handleClickDelete}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(InvitationCodes);
