import React, { FC, ReactNode } from "react";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../../routing/routes";

export interface AuthorizedUserGuardProps {
  children: ReactNode;
}

const AuthorizedUserGuard: FC<AuthorizedUserGuardProps> = ({ children }) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  if (!authService.isLoggedIn) {
    return <Navigate to={ROUTES.login} />;
  }

  return <>{children}</>;
};

export default AuthorizedUserGuard;
