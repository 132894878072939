import { chakra, HTMLChakraProps } from "@chakra-ui/react";

export const Logo = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    color="accent"
    height="12"
    width="auto"
    viewBox="0 0 89 89"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <text
      x="50%"
      y="50%"
      dominantBaseline="middle"
      textAnchor="middle"
      fontSize="56"
      fill="currentColor"
      fontWeight="bold"
      fontFamily="Arial, sans-serif"
    >
      TAU24 Logo
    </text>
  </chakra.svg>
);

export default Logo;
