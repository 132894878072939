import { ROUTES } from "./routes";
import { lazy } from "react";
import { appCreateBrowserRouter } from "@shared/router";
import { Login } from "@ui/auth/login";
import { UnauthorizedUserGuard } from "@shared/components/guards/unauthorized-user-guard";
import { AuthorizedUserGuard } from "@shared/components/guards/authorized-user-guard";
import { HomeScreen } from "@ui/private/home";
import { PrivateLayout } from "@shared/components/layouts/private-layout";
import { TermsDocuments } from "@ui/private/terms-documents";
import { InvitationCodes } from "@ui/private/invitation-codes";
import { InfoDocuments } from "@ui/private/info-documents";
import { TermsDocumentsVersions } from "@ui/private/terms-documents-versions";
import { InfoDocumentsVersions } from "@ui/private/info-documents-versions";
import { Navigate } from "react-router-dom";

const App = lazy(() => import("@ui/app/app"));

export const router = appCreateBrowserRouter([
  {
    path: ROUTES.initial,
    element: <App />,
    errorElement: <div>Page Not Found</div>,
    children: [
      {
        path: ROUTES.login,
        element: (
          <UnauthorizedUserGuard>
            <Login />
          </UnauthorizedUserGuard>
        ),
      },
      {
        path: ROUTES.home,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayout>
              <Navigate to={ROUTES.invitationCodes} />
            </PrivateLayout>
          </AuthorizedUserGuard>
        ),
      },
      {
        path: ROUTES.invitationCodes,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayout>
              <InvitationCodes />
            </PrivateLayout>
          </AuthorizedUserGuard>
        ),
      },
      {
        path: ROUTES.termsDocuments,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayout>
              <TermsDocuments />
            </PrivateLayout>
          </AuthorizedUserGuard>
        ),
      },
      {
        path: ROUTES.termsDocumentsVersions(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayout>
              <TermsDocumentsVersions />
            </PrivateLayout>
          </AuthorizedUserGuard>
        ),
      },
      {
        path: ROUTES.infoDocuments,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayout>
              <InfoDocuments />
            </PrivateLayout>
          </AuthorizedUserGuard>
        ),
      },
      {
        path: ROUTES.infoDocumentsVersions(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayout>
              <InfoDocumentsVersions />
            </PrivateLayout>
          </AuthorizedUserGuard>
        ),
      },
    ],
  },
]);
