import React, { FC, useCallback, useState } from "react";
import { styles } from "./info-documents.styles";
import { WithStyles, withStyles } from "@mui/styles";
import { PageLayout } from "@shared/components/layouts/page-layout";
import { Button } from "@chakra-ui/react";
import { useMutation, UseMutationResult } from "react-query";
import { IdDto } from "@shared/types/id.dto";
import { InfoDocumentsViewModel } from "./info-documents.vm";
import { InfoDocumentsTable } from "./components/info-documents-table";
import { t } from "@lingui/macro";
import { ConfirmModal } from "@shared/components/confirm-modal";
import { InviteCodeDto } from "@shared/models/invite-codes/invite-code.model";
import { IAddDocumentForm } from "@shared/components/add-document-modal/add-document-modal.validator";
import AddDocumentModal from "@shared/components/add-document-modal/add-document-modal";

export type InfoDocumentsProps = WithStyles<typeof styles>;

const InfoDocuments: FC<InfoDocumentsProps> = ({
  classes,
}: InfoDocumentsProps) => {
  const $vm = React.useMemo(() => new InfoDocumentsViewModel(), []);

  const [reloadGridData, setReloadGridData] = useState<(() => void) | null>(
    null,
  );

  const [serverError, setServerError] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<InviteCodeDto | null>(null);

  const createDocumentMutation: UseMutationResult<
    IdDto,
    unknown,
    IAddDocumentForm,
    unknown
  > = useMutation(
    (data: IAddDocumentForm): Promise<IdDto> => $vm.createDocument(data),
    {
      onSuccess: () => {
        setIsOpenAddDocumentModal(false);
        if (reloadGridData) {
          reloadGridData();
        }
      },
      onError: () => {
        setServerError("Server Error");
      },
    },
  );

  const deleteDocumentMutation: UseMutationResult<
    void,
    unknown,
    IdDto,
    unknown
  > = useMutation((data: IdDto): Promise<void> => $vm.deleteDocument(data.id), {
    onSuccess: () => {
      setIsOpenConfirmModal(false);
      if (reloadGridData) {
        reloadGridData();
      }
    },
    onError: () => {
      setServerError("Server Error");
    },
  });

  const [isOpenAddDocumentModal, setIsOpenAddDocumentModal] = useState(false);

  const handleCloseAddInvitationModal = () => {
    setIsOpenAddDocumentModal(false);
    setServerError("");
  };

  const handleSetReloadGridData = useCallback((reloadFunction: () => void) => {
    setReloadGridData(() => reloadFunction);
  }, []);

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
    setSelectedCode(null);
    setServerError("");
  };

  const handleClickDelete = useCallback((data: InviteCodeDto) => {
    setSelectedCode(data);
    setIsOpenConfirmModal(true);
  }, []);

  return (
    <PageLayout
      title={t`Info Documents`}
      description={t`This section is for managing Info Documents`}
      primaryButton={
        <Button
          variant="outline"
          onClick={() => {
            setIsOpenAddDocumentModal(true);
          }}
        >{t`Create document`}</Button>
      }
    >
      <AddDocumentModal
        isOpen={isOpenAddDocumentModal}
        onClose={handleCloseAddInvitationModal}
        onSave={(formData: IAddDocumentForm) =>
          createDocumentMutation.mutate(formData)
        }
        isLoading={createDocumentMutation.isLoading}
        error={serverError}
      />
      <ConfirmModal
        title={t`Confirm deletion`}
        description={t`Do you really want to remove the document ${selectedCode?.code} ?`}
        isOpen={isOpenConfirmModal}
        isLoading={deleteDocumentMutation.isLoading}
        error={serverError}
        onConfirm={() =>
          deleteDocumentMutation.mutate({ id: selectedCode?.id || "" })
        }
        onClose={handleCloseConfirmModal}
      />
      <InfoDocumentsTable
        classes={classes}
        onReload={handleSetReloadGridData}
        onDelete={handleClickDelete}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(InfoDocuments);
