import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IDocumentsService } from "@shared/interfaces/documents-service.interface";
import { CreateDocumentDto } from "@shared/types/documents/create-document.dto";

export class TermsDocumentsViewModel {
  private documentsService = appInject<IDocumentsService>(
    DI_TOKENS.documentsService,
  );

  createDocument(dto: CreateDocumentDto) {
    return this.documentsService.createTermsDocument(dto);
  }

  deleteDocument(id: string) {
    return this.documentsService.deleteTermsDocument({ id });
  }
}
