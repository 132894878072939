import React, { FC, useCallback, useState } from "react";
import { styles } from "./info-documents-versions.styles";
import { WithStyles, withStyles } from "@mui/styles";
import { PageLayout } from "@shared/components/layouts/page-layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
} from "@chakra-ui/react";
import { useMutation, UseMutationResult } from "react-query";
import { IdDto } from "@shared/types/id.dto";
import { InfoDocumentsVersionsViewModel } from "./info-documents-versions.vm";
import { InfoDocumentsTable } from "./components/info-documents-versions-table";
import { t } from "@lingui/macro";
import { ConfirmModal } from "@shared/components/confirm-modal";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import { appObserver } from "@core/state-management/utils";
import { DocumentVersionDto } from "@shared/models/documents/document-version.model";
import { VersionIdDto } from "@shared/types/version-id.dto";
import { IVersionForm } from "@shared/components/document-version-form/document-version-form.validator";
import { DocumentVersionForm } from "@shared/components/document-version-form";
import { InfoDocumentVersionActiveScreen } from "@ui/private/info-documents-versions/info-documents-version-active.screen.enum";

export type InfoDocumentsVersionsProps = WithStyles<typeof styles>;

const InfoDocumentsVersions: FC<InfoDocumentsVersionsProps> = appObserver(
  ({ classes }: InfoDocumentsVersionsProps) => {
    const $vm = React.useMemo(() => new InfoDocumentsVersionsViewModel(), []);

    const { documentId } = useParams();

    const [reloadGridData, setReloadGridData] = useState<(() => void) | null>(
      null,
    );

    const [serverError, setServerError] = useState("");
    const [isOpenConfirmReleaseModal, setIsOpenConfirmReleaseModal] =
      useState<boolean>(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] =
      useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] =
      useState<DocumentVersionDto | null>(null);

    const createVersionMutation: UseMutationResult<
      IdDto,
      unknown,
      IVersionForm,
      unknown
    > = useMutation(
      (data: IVersionForm): Promise<IdDto> =>
        $vm.createVersion(documentId || "", data),
      {
        onSuccess: () => {
          $vm.closeVersionForm();
          if (reloadGridData) {
            reloadGridData();
          }
        },
        onError: () => {
          setServerError("Server Error");
        },
      },
    );

    const updateVersionMutation: UseMutationResult<
      void,
      unknown,
      IVersionForm,
      unknown
    > = useMutation(
      (data: IVersionForm): Promise<void> =>
        $vm.updateVersion(documentId || "", selectedDocument, data),
      {
        onSuccess: () => {
          $vm.closeVersionForm();
          if (reloadGridData) {
            reloadGridData();
          }
        },
        onError: () => {
          setServerError("Server Error");
        },
      },
    );

    const releaseVersionMutation: UseMutationResult<
      void,
      unknown,
      VersionIdDto,
      unknown
    > = useMutation(
      (): Promise<void> =>
        $vm.releaseVersion(documentId || "", selectedDocument),
      {
        onSuccess: () => {
          setIsOpenConfirmReleaseModal(false);
          if (reloadGridData) {
            reloadGridData();
          }
        },
        onError: () => {
          setServerError("Server Error");
        },
      },
    );

    const deleteDocumentVersionMutation: UseMutationResult<
      void,
      unknown,
      VersionIdDto,
      unknown
    > = useMutation(
      (data: VersionIdDto): Promise<void> => $vm.deleteDocument(data),
      {
        onSuccess: () => {
          setIsOpenConfirmModal(false);
          if (reloadGridData) {
            reloadGridData();
          }
        },
        onError: () => {
          setServerError("Server Error");
        },
      },
    );

    const handleSetReloadGridData = useCallback(
      (reloadFunction: () => void) => {
        setReloadGridData(() => reloadFunction);
      },
      [],
    );

    const handleCloseConfirmModal = () => {
      setIsOpenConfirmModal(false);
      setSelectedDocument(null);
      setServerError("");
    };

    const handleCloseConfirmReleaseModal = () => {
      setIsOpenConfirmReleaseModal(false);
      setSelectedDocument(null);
      setServerError("");
    };

    const handleClickDelete = useCallback((data: DocumentVersionDto) => {
      setSelectedDocument(data);
      setIsOpenConfirmModal(true);
    }, []);

    const handleClickUpdate = useCallback((data: DocumentVersionDto) => {
      setSelectedDocument(data);
      $vm.openUpdatingForm();
    }, []);

    const handleClickRelease = useCallback((data: DocumentVersionDto) => {
      setSelectedDocument(data);
      setIsOpenConfirmReleaseModal(true);
    }, []);

    const documentVersionForm = (
      <DocumentVersionForm
        isLoading={
          createVersionMutation.isLoading || updateVersionMutation.isLoading
        }
        title={$vm.documentVersionFormTitle}
        description={$vm.documentVersionFormDescription}
        isCreating={$vm.documentVersionFormIsCreating}
        onSubmit={(formData: IVersionForm) =>
          $vm.documentVersionFormIsCreating
            ? createVersionMutation.mutate(formData)
            : updateVersionMutation.mutate(formData)
        }
        error={serverError}
        data={selectedDocument || undefined}
        onClose={() =>
          $vm.setActiveScreen(InfoDocumentVersionActiveScreen.VERSIONS_TABLE)
        }
      />
    );
    if ($vm.activeScreen === InfoDocumentVersionActiveScreen.DOCUMENTS_FORM) {
      return documentVersionForm;
    }
    return (
      <PageLayout
        title={t`Info Documents Details`}
        description={
          <Breadcrumb separator="-">
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Link}
                to={ROUTES.infoDocuments}
              >{t`Info Documents`}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">{documentId}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        }
        primaryButton={
          <Button
            variant="outline"
            onClick={() => $vm.openCreatingForm()}
          >{t`Create new version`}</Button>
        }
      >
        <ConfirmModal
          title={t`Confirm deletion`}
          description={t`Do you really want to remove the version ${selectedDocument?.version} ?`}
          isOpen={isOpenConfirmModal}
          isLoading={deleteDocumentVersionMutation.isLoading}
          error={serverError}
          onConfirm={() =>
            deleteDocumentVersionMutation.mutate({
              id: documentId || "",
              versionId: selectedDocument?.id || "",
            })
          }
          onClose={handleCloseConfirmModal}
        />
        <ConfirmModal
          title={t`Confirm release`}
          description={t`Do you really want to release the version ${selectedDocument?.version} ?`}
          isOpen={isOpenConfirmReleaseModal}
          isLoading={releaseVersionMutation.isLoading}
          error={serverError}
          onConfirm={() =>
            releaseVersionMutation.mutate({
              id: documentId || "",
              versionId: selectedDocument?.id || "",
            })
          }
          onClose={handleCloseConfirmReleaseModal}
        />
        <InfoDocumentsTable
          classes={classes}
          onReload={handleSetReloadGridData}
          onDelete={handleClickDelete}
          onUpdate={handleClickUpdate}
          onRelease={handleClickRelease}
          documentId={documentId || ""}
        />
      </PageLayout>
    );
  },
);

export default withStyles(styles)(InfoDocumentsVersions);
