import React, { FC, KeyboardEvent, useEffect } from "react";
import { styles } from "./change-password-form.styles";

import { Box, Button, Stack } from "@chakra-ui/react";
import { PasswordField } from "@shared/components/password-field";
import { withStyles, WithStyles } from "@mui/styles";
import { t } from "@lingui/macro";
import { useForm } from "react-hook-form";

import { nameOf } from "@shared/utils/nameof";
import {
  ChangePasswordFieldsResolver,
  ChangePasswordFormFields,
  IChangePasswordForm,
} from "@ui/auth/login/forms/change-password-form/change-password-form.validator";

export interface ChangePasswordFormProps extends WithStyles<typeof styles> {
  onSubmit: (formData: IChangePasswordForm) => void;
  isLoading: boolean;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  onSubmit,
  isLoading,
}: ChangePasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ChangePasswordFormFields>({
    resolver: ChangePasswordFieldsResolver,
  });

  const submitForm = (formData: IChangePasswordForm) => {
    onSubmit(formData);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  return (
    <Box
      py={{ base: "0", sm: "8" }}
      px={{ base: "4", sm: "10" }}
      bg={{ base: "transparent", sm: "bg.surface" }}
      boxShadow={{ base: "none", sm: "md" }}
      borderRadius={{ base: "none", sm: "xl" }}
    >
      <form
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <Stack spacing="6">
          <Stack spacing="5">
            <PasswordField
              disabled={isLoading}
              label={t`Password`}
              error={errors.password?.message}
              controls={register(nameOf<IChangePasswordForm>("password"))}
            />
            <PasswordField
              disabled={isLoading}
              label={t`Password confirm`}
              error={errors.passwordRepeat?.message}
              controls={register(nameOf<IChangePasswordForm>("passwordRepeat"))}
            />
          </Stack>
          <Stack spacing="6">
            <Button
              type={"submit"}
              disabled={!isValid}
              isLoading={isLoading}
            >{t`Change`}</Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default withStyles(styles)(ChangePasswordForm);
