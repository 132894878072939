import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IInvitationCodeService } from "@shared/interfaces/invitation-code-service.interface";
import { IAddInvitationCodeForm } from "@ui/private/invitation-codes/components/add-invitation-code-modal/add-invitation-code.validator";

export class InvitationCodesViewModel {
  private invitationCodeService = appInject<IInvitationCodeService>(
    DI_TOKENS.invitationCodeService,
  );

  createCode(dto: IAddInvitationCodeForm) {
    return this.invitationCodeService.create(dto);
  }

  deleteCode(id: string) {
    return this.invitationCodeService.delete({ id });
  }
}
