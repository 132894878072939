import React, { FC, useRef, useEffect, useState, useCallback } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  DataGridTypes,
  FilterPanel,
  FilterRow,
  Pager,
  Paging,
  Position,
  Scrolling,
  Sorting,
  StateStoring,
  Summary,
  TotalItem,
  ValueFormat,
} from "devextreme-react/data-grid";
import { IconButton, Link } from "@chakra-ui/react";
import { FiTrash } from "react-icons/fi";
import { InfoDocumentsTableViewModel } from "./info-documents-table.vm";
import { withStyles, WithStyles } from "@mui/styles";
import { styles } from "./info-documents-table.styles";
import { InviteCodeDto } from "@shared/models/invite-codes/invite-code.model";
import { ROUTES } from "../../../../../routing/routes";
import { useNavigate } from "react-router-dom";

interface InfoDocumentsTableProps extends WithStyles<typeof styles> {
  onReload: (reloadFunction: () => void) => void;
  onDelete: (data: InviteCodeDto) => void;
}

const InfoDocumentsTable: FC<InfoDocumentsTableProps> = ({
  classes,
  onReload,
  onDelete,
}) => {
  const $vm = React.useMemo(() => new InfoDocumentsTableViewModel(), []);
  const dataGridRef = useRef(null);
  const [filteredColumns, setFilteredColumns] = useState<Array<string>>([]);
  const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
    20, 30, 50,
  ];

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isInsideColumnChooser = target.closest(".dx-datagrid-column-chooser");

    if (!isInsideColumnChooser) {
      (dataGridRef.current as any)?.instance?.hideColumnChooser();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getHeaderCellClassName = (dataField: string) => {
    return filteredColumns.includes(dataField) ? classes.filteredColumn : "";
  };

  const handleOptionChanged = (e: any) => {
    if (
      e.fullName.startsWith("columns[") &&
      e.fullName.endsWith("filterValue")
    ) {
      const columns = (
        dataGridRef.current as any
      )?.instance.getVisibleColumns();
      const filteredColumns = columns.filter(
        (col: any) => col.filterValue !== undefined && col.filterValue !== null,
      );
      setFilteredColumns(filteredColumns.map((col: any) => col.dataField));
    }
  };

  useEffect(() => {
    const instance = (dataGridRef.current as any)?.instance;
    const columns = instance.getVisibleColumns();
    const filteredColumns = columns
      .filter(
        (col: any) => col.filterValue !== undefined && col.filterValue !== null,
      )
      .map((col: any) => col.dataField);
    setFilteredColumns(filteredColumns);
  }, [(dataGridRef.current as any)?.instance]);

  const reloadGridData = useCallback(() => {
    (dataGridRef.current as any)?.instance.refresh();
  }, []);

  useEffect(() => {
    onReload(reloadGridData);
  }, [reloadGridData, onReload]);

  return (
    <div className={classes.content}>
      <DataGrid
        dataSource={$vm.createDataSource()}
        showBorders={false}
        width={"100%"}
        height="calc(100vh - 160px)"
        remoteOperations={true}
        ref={dataGridRef}
        columnAutoWidth={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        allowColumnResizing={true}
        columnMinWidth={50}
        onOptionChanged={handleOptionChanged}
        onEditorPreparing={(e) => {
          if (e.parentType === "filterRow" && e.editorOptions) {
            e.editorOptions.onKeyDown = (args: any) => {
              if (args.event.key === "Enter") {
                e.component.endUpdate();
                e.component.refresh();
              }
            };
          }
        }}
      >
        <FilterRow visible={true} applyFilter={"auto"} />
        <FilterPanel visible={true} />
        <Scrolling rowRenderingMode="virtual" columnRenderingMode="virtual" />
        <Sorting mode="multiple" />
        <Paging defaultPageSize={30} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="info-documents"
        />
        <Summary>
          <TotalItem column="id" summaryType="count">
            <ValueFormat type="decimal" />
          </TotalItem>
        </Summary>
        <Column
          dataField="id"
          caption="ID"
          alignment={"left"}
          cellRender={(cellData) => (
            <Link
              onClick={() =>
                navigate(ROUTES.infoDocumentsVersions(cellData.value))
              }
            >
              {cellData.value}
            </Link>
          )}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="typeId"
          caption="Type ID"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="typeName"
          caption="Type Name"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="slug"
          caption="Slug"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="delete"
          caption="Delete"
          alignment={"center"}
          width={100}
          cellRender={(cellData) => (
            <IconButton
              aria-label="Delete"
              icon={<FiTrash />}
              colorScheme="red"
              variant="outline"
              size="md"
              isRound={true}
              onClick={() => onDelete(cellData.data)}
            />
          )}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <ColumnChooser height={340} enabled={true} mode={"select"}>
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
          <ColumnChooserSearch
            enabled={true}
            editorOptions={{ placeholder: "Search column" }}
          />
          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true}
          />
        </ColumnChooser>
      </DataGrid>
    </div>
  );
};

export default React.memo(withStyles(styles)(InfoDocumentsTable));
