import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import DevExpress from "devextreme";
import { IInvitationCodeService } from "@shared/interfaces/invitation-code-service.interface";
import { IAddInvitationCodeForm } from "@ui/private/invitation-codes/components/add-invitation-code-modal/add-invitation-code.validator";
import { IdDto } from "@shared/types/id.dto";

@appInjectable()
export class InvitationCodeService
  extends BaseService
  implements IInvitationCodeService
{
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClientService = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private baseURL: string;

  constructor() {
    super();
    this.baseURL = this.configService.baseUrl;
  }

  createInvitationCodeStore = (): DevExpress.data.CustomStore => {
    return this.httpClientService.createStore("api/v1/InvitationCodes", "id");
  };

  create = async (dto: IAddInvitationCodeForm): Promise<IdDto> => {
    const { data } = await this.httpClientService.post<IdDto>(
      "/api/v1/InvitationCodes",
      dto,
      { baseURL: this.baseURL },
    );
    return data;
  };

  delete = async (dto: IdDto): Promise<void> => {
    await this.httpClientService.delete(`/api/v1/InvitationCodes/${dto.id}`, {
      baseURL: this.baseURL,
    });
  };
}
