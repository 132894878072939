import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { IsNotEmpty, IsString, MinLength } from "class-validator";

export interface IVersionForm {
  lang: string;
  title: string;
  lastUpdatedDate: string;
  body: string;
}

export class VersionFormFields implements IVersionForm {
  @IsString()
  @IsNotEmpty()
  @MinLength(2)
  lang: string;

  @IsString()
  @IsNotEmpty()
  @MinLength(2)
  title: string;

  lastUpdatedDate: string;

  @IsString()
  @IsNotEmpty()
  @MinLength(2)
  body: string;
}

export const VersionFormFieldsResolver =
  classValidatorResolver(VersionFormFields);
