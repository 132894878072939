export const ROUTES = {
  initial: "/",
  login: "/login",
  home: "/home",
  invitationCodes: "/invitation-codes",
  termsDocuments: "/terms-documents",
  termsDocumentsVersions: (documentId: string = ":documentId") =>
    `/terms-documents/${documentId}`,
  infoDocuments: "/info-documents",
  infoDocumentsVersions: (documentId: string = ":documentId") =>
    `/info-documents/${documentId}`,
};
