export const validationRegExpr = {
  oneLowerCase: /[a-z]/,
  oneUpperCase: /[A-Z]/,
  oneDigital: /[0-9]/,
  specialChar: /[!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  specialAndDigitChar: /[0-9!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  minimumLength: /^.{8,}$/,
  whiteSpace: /^(?![\s]).*[\S]+$/,
  phone: /^\+[0-9']{11,15}$/,
};
