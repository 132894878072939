import { DiEntity, DiEntityIdentifier } from "@core/di/types";
import { DI_TOKENS } from "@shared/constants/di";
import { LocalesViewModel } from "@shared/view-models/locales.view-model";
import { AuthService } from "@shared/services/auth.service";
import { InvitationCodeService } from "@shared/services/invitation-code.service";
import { DocumentsService } from "@shared/services/documents.service";

export const sharedAppServices: Array<{
  diToken: DiEntityIdentifier;
  entity: DiEntity;
}> = [
  { diToken: DI_TOKENS.localesViewModel, entity: LocalesViewModel },
  { diToken: DI_TOKENS.authService, entity: AuthService },
  { diToken: DI_TOKENS.invitationCodeService, entity: InvitationCodeService },
  { diToken: DI_TOKENS.documentsService, entity: DocumentsService },
];
