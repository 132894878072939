import { Validate } from "class-validator";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { PasswordStrength } from "@ui/auth/login/validators/password-strength";
import { SamePassword } from "@ui/auth/login/validators/same-password";

export interface IChangePasswordForm {
  password: string;
  passwordRepeat: string;
}

export class ChangePasswordFormFields implements IChangePasswordForm {
  @Validate(PasswordStrength)
  password: string;

  @Validate(SamePassword)
  passwordRepeat: string;
}

export const ChangePasswordFieldsResolver = classValidatorResolver(
  ChangePasswordFormFields,
);
