import { DiEntityIdentifier } from "@core/di/types";

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x;
}

export const DI_TOKENS = createTokens({
  baseService: "base-service",
  httpClientService: "http-client-service",
  configService: "config-service",
  storageService: "storage-service",
  localesViewModel: "locales-view-model",
  authService: "auth-service",
  invitationCodeService: "invitation-code-service",
  documentsService: "documents-service",
});
