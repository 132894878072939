import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IDocumentsService } from "@shared/interfaces/documents-service.interface";
import { DocumentDetailsModel } from "@shared/models/documents/document-details.model";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { TermsDocumentVersionActiveScreen } from "@ui/private/terms-documents-versions/terms-documents-version-active.screen.enum";
import { t } from "@lingui/macro";
import { VersionIdDto } from "@shared/types/version-id.dto";
import { DocumentVersionDto } from "@shared/models/documents/document-version.model";
import { IVersionForm } from "@shared/components/document-version-form/document-version-form.validator";

export class TermsDocumentsVersionsViewModel {
  private document: DocumentDetailsModel | null = null;
  private _activeScreen: TermsDocumentVersionActiveScreen =
    TermsDocumentVersionActiveScreen.VERSIONS_TABLE;
  private _documentVersionFormTitle: string = "";
  private _documentVersionFormDescription: string = "";
  private _documentVersionFormIsCreating: boolean = false;

  private documentsService = appInject<IDocumentsService>(
    DI_TOKENS.documentsService,
  );

  constructor() {
    appMakeObservable(this, {
      _activeScreen: appObservable,
      _documentVersionFormTitle: appObservable,
      _documentVersionFormDescription: appObservable,
      _documentVersionFormIsCreating: appObservable,
    });
  }

  setActiveScreen(screen: TermsDocumentVersionActiveScreen): void {
    this._activeScreen = screen;
  }

  get activeScreen() {
    return this._activeScreen;
  }

  get documentVersionFormTitle() {
    return this._documentVersionFormTitle;
  }

  get documentVersionFormDescription() {
    return this._documentVersionFormDescription;
  }

  get documentVersionFormIsCreating() {
    return this._documentVersionFormIsCreating;
  }

  get slug() {
    return this.document?.asJson.slug || "";
  }

  openCreatingForm() {
    this._documentVersionFormTitle = t`Create document version`;
    this._documentVersionFormDescription = "";
    this._documentVersionFormIsCreating = true;
    this.setActiveScreen(TermsDocumentVersionActiveScreen.DOCUMENTS_FORM);
  }

  openUpdatingForm() {
    this._documentVersionFormTitle = t`Update document version`;
    this._documentVersionFormDescription = "";
    this._documentVersionFormIsCreating = false;
    this.setActiveScreen(TermsDocumentVersionActiveScreen.DOCUMENTS_FORM);
  }

  async getDocumentDetails(id: string) {
    const data = await this.documentsService.getTermsDocument({ id });
    this.document = data;
    return data;
  }

  closeVersionForm() {
    this._documentVersionFormTitle = "";
    this._documentVersionFormDescription = "";
    this._documentVersionFormIsCreating = false;
    this.setActiveScreen(TermsDocumentVersionActiveScreen.VERSIONS_TABLE);
  }

  createVersion(id: string, dto: IVersionForm) {
    return this.documentsService.createTermsDocumentVersion({ id, ...dto });
  }

  updateVersion(
    id: string,
    dto: DocumentVersionDto | null,
    formData: IVersionForm,
  ) {
    return this.documentsService.updateTermsDocumentVersion({
      id: id,
      versionId: dto?.id || "",
      title: formData.title,
      lastUpdatedDate: formData.lastUpdatedDate,
      body: formData.body,
    });
  }

  releaseVersion(id: string, dto: DocumentVersionDto | null) {
    return this.documentsService.releaseTermsDocumentVersion({
      id: id,
      versionId: dto?.id || "",
    });
  }

  deleteDocument(dto: VersionIdDto) {
    return this.documentsService.deleteVersionOfTermsDocument(dto);
  }
}
