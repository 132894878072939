import React, { FC, useRef, useEffect, useState, useCallback } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  DataGridTypes,
  FilterPanel,
  FilterRow,
  Pager,
  Paging,
  Position,
  Scrolling,
  Sorting,
  StateStoring,
  Summary,
  TotalItem,
  ValueFormat,
} from "devextreme-react/data-grid";
import { Box, IconButton } from "@chakra-ui/react";
import { FiCheckCircle, FiEdit, FiTrash, FiXCircle } from "react-icons/fi";
import { InfoDocumentsTableViewModel } from "./info-documents-versions-table.vm";
import { withStyles, WithStyles } from "@mui/styles";
import { styles } from "./info-documents-versions-table.styles";
import { DocumentVersionDto } from "@shared/models/documents/document-version.model";

interface InvitationCodesTableProps extends WithStyles<typeof styles> {
  onReload: (reloadFunction: () => void) => void;
  onDelete: (data: DocumentVersionDto) => void;
  onUpdate: (data: DocumentVersionDto) => void;
  onRelease: (data: DocumentVersionDto) => void;
  documentId: string;
}

const InfoDocumentsVersionsTable: FC<InvitationCodesTableProps> = ({
  classes,
  onReload,
  onDelete,
  onUpdate,
  onRelease,
  documentId,
}) => {
  const $vm = React.useMemo(() => new InfoDocumentsTableViewModel(), []);
  const dataGridRef = useRef(null);
  const [filteredColumns, setFilteredColumns] = useState<Array<string>>([]);
  const [dataSource, setDataSource] = useState<DocumentVersionDto[]>([]);
  const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
    20, 30, 50,
  ];

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isInsideColumnChooser = target.closest(".dx-datagrid-column-chooser");

    if (!isInsideColumnChooser) {
      (dataGridRef.current as any)?.instance?.hideColumnChooser();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getHeaderCellClassName = (dataField: string) => {
    return filteredColumns.includes(dataField) ? classes.filteredColumn : "";
  };

  const handleOptionChanged = (e: any) => {
    if (
      e.fullName.startsWith("columns[") &&
      e.fullName.endsWith("filterValue")
    ) {
      const columns = (
        dataGridRef.current as any
      )?.instance.getVisibleColumns();
      const filteredColumns = columns.filter(
        (col: any) => col.filterValue !== undefined && col.filterValue !== null,
      );
      setFilteredColumns(filteredColumns.map((col: any) => col.dataField));
    }
  };

  useEffect(() => {
    const instance = (dataGridRef.current as any)?.instance;
    const columns = instance.getVisibleColumns();
    const filteredColumns = columns
      .filter(
        (col: any) => col.filterValue !== undefined && col.filterValue !== null,
      )
      .map((col: any) => col.dataField);
    setFilteredColumns(filteredColumns);
  }, [(dataGridRef.current as any)?.instance]);

  const reloadGridData = useCallback(async () => {
    const response = await $vm.getDocumentDetails(documentId);
    setDataSource(response.asJson.versions || []);
  }, [documentId, $vm]);

  useEffect(() => {
    reloadGridData();
    onReload(reloadGridData);
  }, [reloadGridData, onReload]);

  return (
    <div className={classes.content}>
      <DataGrid
        dataSource={dataSource}
        showBorders={false}
        width={"100%"}
        height="calc(100vh - 160px)"
        remoteOperations={true}
        ref={dataGridRef}
        columnAutoWidth={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        allowColumnResizing={true}
        columnMinWidth={50}
        onOptionChanged={handleOptionChanged}
        onEditorPreparing={(e) => {
          if (e.parentType === "filterRow" && e.editorOptions) {
            e.editorOptions.onKeyDown = (args: any) => {
              if (args.event.key === "Enter") {
                e.component.endUpdate();
                e.component.refresh();
              }
            };
          }
        }}
      >
        <FilterRow visible={true} applyFilter={"auto"} />
        <FilterPanel visible={true} />
        <Scrolling rowRenderingMode="virtual" columnRenderingMode="virtual" />
        <Sorting mode="multiple" />
        <Paging defaultPageSize={30} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="info-documents-versions"
        />
        <Summary>
          <TotalItem column="id" summaryType="count">
            <ValueFormat type="decimal" />
          </TotalItem>
        </Summary>
        <Column
          dataField="id"
          caption="ID"
          alignment={"left"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="title"
          caption="Title"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="version"
          caption="Version"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="lang"
          caption="Language"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="lastUpdateDate"
          caption="Updated Date"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <Column
          dataField="isReleased"
          caption="Is released"
          alignment={"center"}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
          cellRender={(cellData) =>
            cellData.data.isReleased ? (
              <IconButton
                style={{ cursor: "unset" }}
                aria-label="Active"
                icon={<FiCheckCircle />}
                colorScheme="green"
                variant="outline"
                disabled={true}
                size="md"
                isRound={true}
                onClick={() => {}}
              />
            ) : (
              <IconButton
                aria-label="Inactive"
                icon={<FiXCircle />}
                colorScheme="grey"
                variant="outline"
                size="md"
                isRound={true}
                onClick={() => onRelease(cellData.data)}
              />
            )
          }
        />
        <Column
          dataField="delete"
          caption="Actions"
          alignment={"center"}
          width={100}
          cellRender={(cellData) => (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <IconButton
                aria-label="Update"
                icon={<FiEdit />}
                colorScheme="yellow"
                variant="outline"
                size="md"
                isRound={false}
                onClick={() => onUpdate(cellData.data)}
              />
              <IconButton
                aria-label="Delete"
                icon={<FiTrash />}
                colorScheme="red"
                variant="outline"
                size="md"
                isRound={false}
                onClick={() => onDelete(cellData.data)}
              />
            </Box>
          )}
          headerCellRender={(cellData) => (
            <div className={getHeaderCellClassName(cellData.column.dataField)}>
              {cellData.column.caption}
            </div>
          )}
        />
        <ColumnChooser height={340} enabled={true} mode={"select"}>
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
          <ColumnChooserSearch
            enabled={true}
            editorOptions={{ placeholder: "Search column" }}
          />
          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true}
          />
        </ColumnChooser>
      </DataGrid>
    </div>
  );
};

export default React.memo(withStyles(styles)(InfoDocumentsVersionsTable));
