import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { IsNotEmpty, IsString, MinLength } from "class-validator";

export interface IAddDocumentForm {
  slug: string;
}

export class AddDocumentFormFields implements IAddDocumentForm {
  @IsString()
  @IsNotEmpty()
  @MinLength(2)
  slug: string;
}

export const AddDocumentFormFieldsResolver = classValidatorResolver(
  AddDocumentFormFields,
);
