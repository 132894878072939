import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IDocumentsService } from "@shared/interfaces/documents-service.interface";

export class TermsDocumentsTableViewModel {
  private documentsService = appInject<IDocumentsService>(
    DI_TOKENS.documentsService,
  );

  createDataSource() {
    return this.documentsService.createTermsDocumentsStore();
  }
}
