import { BaseModel } from "@core/models/base-model";

export interface SSOUserSessionDTO {
  userId?: string;
  anonymous: boolean;
}

export class SSOUserSessionModel extends BaseModel<SSOUserSessionDTO> {
  private userId?: string;
  private anonymous: boolean;

  constructor(data: SSOUserSessionDTO) {
    super();

    this.update(data);
  }

  get asJson(): SSOUserSessionDTO {
    return {
      userId: this.userId || "",
      anonymous: this.anonymous,
    };
  }
}
