import React, { FC, ReactNode } from "react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import {
  FiUserPlus,
  FiHelpCircle,
  FiMoreVertical,
  FiSettings,
  FiFileText,
  FiInfo,
} from "react-icons/fi";
import { styles } from "./private-layout.styles";
import { WithStyles, withStyles } from "@mui/styles";
import { SidebarLogo } from "@shared/components/images/sidebar-ogo";
import { SidebarButton } from "@shared/components/sidebar-button";
import { ROUTES } from "../../../../routing/routes";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { t } from "@lingui/macro";

export interface PrivateLayoutProps extends WithStyles<typeof styles> {
  children: ReactNode;
}

const PrivateLayout: FC<PrivateLayoutProps> = ({
  classes,
  children,
}: PrivateLayoutProps) => {
  return (
    <Flex as="section" minH="100vh">
      <Stack
        flex="1"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        bg="bg.surface"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="8">
          <SidebarLogo alignSelf="start" />
          <Stack spacing="1">
            <NavLink
              to={ROUTES.invitationCodes}
              className={({ isActive }) =>
                classNames(classes.navLink, { [classes.active]: isActive })
              }
            >
              <SidebarButton
                leftIcon={<FiUserPlus />}
                className={classes.button}
              >
                {t`Invitation Codes`}
              </SidebarButton>
            </NavLink>
            <NavLink
              to={ROUTES.termsDocuments}
              className={({ isActive }) =>
                classNames(classes.navLink, { [classes.active]: isActive })
              }
            >
              <SidebarButton
                leftIcon={<FiFileText />}
                className={classes.button}
              >
                {t`Terms Documents`}
              </SidebarButton>
            </NavLink>
            <NavLink
              to={ROUTES.infoDocuments}
              className={({ isActive }) =>
                classNames(classes.navLink, { [classes.active]: isActive })
              }
            >
              <SidebarButton leftIcon={<FiInfo />} className={classes.button}>
                {t`Info Documents`}
              </SidebarButton>
            </NavLink>
          </Stack>
        </Stack>
        <Stack spacing="4" divider={<StackDivider />}>
          <Box />
          <Stack spacing="1">
            <SidebarButton leftIcon={<FiHelpCircle />}>
              Help Center
            </SidebarButton>
            <SidebarButton leftIcon={<FiSettings />}>Settings</SidebarButton>
          </Stack>
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <Avatar boxSize="10" src="https://i.pravatar.cc/300" />
              <Box>
                <Text textStyle="sm" fontWeight="medium">
                  John Doe
                </Text>
                <Text textStyle="sm" color="fg.muted">
                  john@chakra-ui.com
                </Text>
              </Box>
            </HStack>
            <IconButton
              variant="tertiary"
              icon={<FiMoreVertical />}
              aria-label="Open Menu"
            />
          </HStack>
        </Stack>
      </Stack>
      <Flex flex="1" bg="bg.light">
        {children}
      </Flex>
    </Flex>
  );
};

export default withStyles(styles)(PrivateLayout);
