import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IInvitationCodeService } from "@shared/interfaces/invitation-code-service.interface";

export class InvitationCodesTableViewModel {
  private invitationCodeService = appInject<IInvitationCodeService>(
    DI_TOKENS.invitationCodeService,
  );

  createDataSource() {
    return this.invitationCodeService.createInvitationCodeStore();
  }
}
