import React, { FC, useMemo, useState } from "react";
import { styles } from "./login.styles";
import { appObserver } from "@core/state-management/utils";

import { Container, Heading, Stack } from "@chakra-ui/react";
import { ChakraLogo } from "@shared/components/images/chakra-logo";
import { withStyles, WithStyles } from "@mui/styles";
import { LoginViewModel } from "@ui/auth/login/login.vm";
import { LoginScreen } from "@ui/auth/login/login-screen";
import { LoginForm } from "@ui/auth/login/forms/login-form";
import { t } from "@lingui/macro";
import { useMutation, UseMutationResult } from "react-query";
import { ILoginForm } from "@ui/auth/login/forms/login-form/login-form.validator";
import { useNavigate } from "react-router-dom";
import { AuthStatusData } from "@shared/interfaces/auth-service.interface";
import { ChangePasswordForm } from "@ui/auth/login/forms/change-password-form";
import { IChangePasswordForm } from "@ui/auth/login/forms/change-password-form/change-password-form.validator";

export type LoginProps = WithStyles<typeof styles>;

const Login: FC<LoginProps> = appObserver(({ classes }: LoginProps) => {
  const navigate = useNavigate();

  const $vm = useMemo(() => new LoginViewModel().setNavigate(navigate), []);
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

  const loginMutation: UseMutationResult<
    AuthStatusData,
    unknown,
    ILoginForm,
    unknown
  > = useMutation(
    (data: ILoginForm): Promise<AuthStatusData> => $vm.login(data),
    {
      onSuccess: (statusData) => $vm.handleSuccessLogin(statusData),
      onError: (err) => {
        setIsInvalidCredentials(true);
      },
    },
  );

  const changePasswordMutation: UseMutationResult<
    AuthStatusData,
    unknown,
    IChangePasswordForm,
    unknown
  > = useMutation(
    (data: IChangePasswordForm): Promise<AuthStatusData> =>
      $vm.savePassword(data.password),
    {
      onSuccess: (statusData) => $vm.handleSuccessLogin(statusData),
      onError: (err) => {
        setIsInvalidCredentials(true);
      },
    },
  );

  let form = <></>;
  if ($vm.activeScreen === LoginScreen.LOGIN_FORM) {
    form = (
      <LoginForm
        onSubmit={(formData) => {
          loginMutation.mutate(formData);
        }}
        isLoading={loginMutation.isLoading}
        isInvalidCredentials={isInvalidCredentials}
      />
    );
  } else if ($vm.activeScreen === LoginScreen.CHANGE_PASSWORD_FORM) {
    form = (
      <ChangePasswordForm
        onSubmit={(formData) => {
          changePasswordMutation.mutate(formData);
        }}
        isLoading={loginMutation.isLoading}
      />
    );
  }

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <ChakraLogo />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>
              {t`Log in to your account`}
            </Heading>
          </Stack>
        </Stack>
        {form}
      </Stack>
    </Container>
  );
});

export default withStyles(styles)(Login);
