import { createStyles } from "@mui/styles";

export const styles = () => {
  return createStyles({
    content: {
      width: "100%",
      position: "relative",
    },
    filteredColumn: {
      color: "#000",
    },
  });
};
