import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { UseFormRegisterReturn } from "react-hook-form";

export interface PasswordFieldProps extends InputProps {
  label: string;
  error?: string;
  controls: UseFormRegisterReturn<string>;
  disabled?: boolean;
}

export const PasswordField: FC<PasswordFieldProps> = ({
  error,
  label,
  controls,
  disabled,
}: PasswordFieldProps) => {
  const { isOpen, onToggle } = useDisclosure();

  const onClickReveal = () => {
    onToggle();
    if ((controls.ref as any).current) {
      (controls.ref as any).current.focus({ preventScroll: true });
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor="password">{label}</FormLabel>
      <InputGroup>
        <InputRightElement>
          <IconButton
            variant="text"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          id="password"
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          disabled={disabled || false}
          {...controls}
        />
      </InputGroup>
      <FormErrorMessage>{error || ""}</FormErrorMessage>
    </FormControl>
  );
};

PasswordField.displayName = "PasswordField";

export default PasswordField;
