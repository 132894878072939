import React, { FC, ReactNode } from "react";
import { Box, Container, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { styles } from "./page-layout.styles";
import { WithStyles, withStyles } from "@mui/styles";

export interface PageLayoutProps extends WithStyles<typeof styles> {
  children: ReactNode;
  title: string;
  description: string | ReactNode;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({
  children,
  title,
  description,
  primaryButton,
  secondaryButton,
}: PageLayoutProps) => {
  return (
    <Box
      as="section"
      bg="bg.surface"
      pt={{ base: "4", md: "8" }}
      pb={{ base: "12", md: "8" }}
      width={"100%"}
    >
      <Container
        sx={{
          maxWidth: "unset",
        }}
      >
        <Stack
          spacing="4"
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <Stack spacing="1">
            <Heading size={{ base: "xs", md: "sm" }} fontWeight="medium">
              {title}
            </Heading>
            <Text color="fg.muted">{description}</Text>
          </Stack>
          <Stack direction="row" spacing="3">
            {primaryButton || <></>}
            {secondaryButton || <></>}
          </Stack>
        </Stack>
      </Container>
      <Flex
        flex="1"
        overflow="auto"
        height={"calc(100vh - 130px)"}
        padding={"30px"}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default withStyles(styles)(PageLayout);
