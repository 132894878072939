import { appInject, appInjectable } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { AuthConfig, Config } from "@shared/types/config";
import { IConfigService } from "@shared/interfaces/config-service.interface";

@appInjectable()
export class ConfigService implements IConfigService {
  private config: Config;
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );

  initialize() {
    return this.setConfig();
  }

  get() {
    return this.config;
  }

  get authConfig(): AuthConfig {
    return {
      UserPoolId: this.config.cognito_user_pool_id,
      ClientId: this.config.cognito_client_id,
    };
  }

  get mfaDelayMinutes() {
    return 3;
  }

  get version() {
    return "1";
  }

  private async setConfig() {
    this.httpClient.setConfig({
      defaults: {
        baseURL: window.location.origin,
      },
    });

    const { data } = await this.httpClient.get<Config>("./config.json");
    this.config = data;
  }

  get baseUrl() {
    return this.config.base_users_url;
  }
}
