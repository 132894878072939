import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import DevExpress from "devextreme";
import { IDocumentsService } from "@shared/interfaces/documents-service.interface";
import { IdDto } from "@shared/types/id.dto";
import { VersionIdDto } from "@shared/types/version-id.dto";
import { CreateDocumentDto } from "@shared/types/documents/create-document.dto";
import {
  DocumentDetailsDto,
  DocumentDetailsModel,
} from "@shared/models/documents/document-details.model";
import { CreateDocumentVersionDto } from "@shared/types/documents/create-document-version.dto";
import { UpdateDocumentVersionDto } from "@shared/types/documents/update-document-version.dto";

@appInjectable()
export class DocumentsService extends BaseService implements IDocumentsService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClientService = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private baseURL: string;

  constructor() {
    super();
    this.baseURL = this.configService.baseUrl;
  }

  createTermsDocumentsStore = (): DevExpress.data.CustomStore => {
    return this.httpClientService.createStore("api/v1/TermsDocuments", "id");
  };

  createTermsDocumentsVersionStore = (
    id: string,
  ): DevExpress.data.CustomStore => {
    return this.httpClientService.createStore(
      `api/v1/TermsDocuments/${id}`,
      "id",
    );
  };

  createInfoDocumentsVersionStore = (
    id: string,
  ): DevExpress.data.CustomStore => {
    return this.httpClientService.createStore(
      `api/v1/InfoDocuments/${id}`,
      "id",
    );
  };

  createTermsDocument = async (dto: CreateDocumentDto): Promise<IdDto> => {
    const { data } = await this.httpClientService.post<IdDto>(
      `/api/v1/TermsDocuments`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
    return data;
  };

  getTermsDocument = async (dto: IdDto): Promise<DocumentDetailsModel> => {
    const { data } = await this.httpClientService.get<DocumentDetailsDto>(
      `/api/v1/TermsDocuments/${dto.id}`,
      {
        baseURL: this.baseURL,
      },
    );
    return new DocumentDetailsModel(data);
  };

  deleteTermsDocument = async (dto: IdDto): Promise<void> => {
    await this.httpClientService.delete(`/api/v1/TermsDocuments/${dto.id}`, {
      baseURL: this.baseURL,
    });
  };

  createTermsDocumentVersion = async (
    dto: CreateDocumentVersionDto,
  ): Promise<IdDto> => {
    const { data } = await this.httpClientService.post<IdDto>(
      `/api/v1/TermsDocuments/${dto.id}/versions`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
    return data;
  };

  updateTermsDocumentVersion = async (
    dto: UpdateDocumentVersionDto,
  ): Promise<void> => {
    await this.httpClientService.put<IdDto>(
      `/api/v1/TermsDocuments/${dto.id}/versions/${dto.versionId}`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
  };

  deleteVersionOfTermsDocument = async (dto: VersionIdDto): Promise<void> => {
    await this.httpClientService.delete(
      `/api/v1/TermsDocuments/${dto.id}/versions/${dto.versionId}`,
      {
        baseURL: this.baseURL,
      },
    );
  };

  releaseTermsDocumentVersion = async (dto: VersionIdDto): Promise<void> => {
    await this.httpClientService.post<IdDto>(
      `/api/v1/TermsDocuments/${dto.id}/versions/${dto.versionId}/release`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
  };

  createInfoDocumentsStore = (): DevExpress.data.CustomStore => {
    return this.httpClientService.createStore("api/v1/InfoDocuments", "id");
  };

  createInfoDocument = async (dto: CreateDocumentDto): Promise<IdDto> => {
    const { data } = await this.httpClientService.post<IdDto>(
      `/api/v1/InfoDocuments`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
    return data;
  };

  getInfoDocument = async (dto: IdDto): Promise<DocumentDetailsModel> => {
    const { data } = await this.httpClientService.get<DocumentDetailsDto>(
      `/api/v1/InfoDocuments/${dto.id}`,
      {
        baseURL: this.baseURL,
      },
    );
    return new DocumentDetailsModel(data);
  };

  deleteInfoDocument = async (dto: IdDto): Promise<void> => {
    await this.httpClientService.delete(`/api/v1/InfoDocuments/${dto.id}`, {
      baseURL: this.baseURL,
    });
  };

  createInfoDocumentVersion = async (
    dto: CreateDocumentVersionDto,
  ): Promise<IdDto> => {
    const { data } = await this.httpClientService.post<IdDto>(
      `/api/v1/InfoDocuments/${dto.id}/versions`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
    return data;
  };

  updateInfoDocumentVersion = async (
    dto: UpdateDocumentVersionDto,
  ): Promise<void> => {
    await this.httpClientService.put<IdDto>(
      `/api/v1/InfoDocuments/${dto.id}/versions/${dto.versionId}`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
  };

  deleteVersionOfInfoDocument = async (dto: VersionIdDto): Promise<void> => {
    await this.httpClientService.delete(
      `/api/v1/InfoDocuments/${dto.id}/versions/${dto.versionId}`,
      {
        baseURL: this.baseURL,
      },
    );
  };

  releaseInfoDocumentVersion = async (dto: VersionIdDto): Promise<void> => {
    await this.httpClientService.post<IdDto>(
      `/api/v1/InfoDocuments/${dto.id}/versions/${dto.versionId}/release`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );
  };
}
