import { appInjectable } from "@core/di/utils";

import Cookies from "js-cookie";
import {
  set as setLocalStorage,
  get as getLocalStorage,
  remove as removeLocalStorage,
} from "local-storage";
import {
  IStorageService,
  StorageKey,
  StorageSetOptions,
  StorageType,
} from "@shared/interfaces/storage-service.interface";

type Methods = {
  get: <T>(key: StorageKey) => T | undefined;
  set: <T>(key: StorageKey, value: T, options?: StorageSetOptions) => void;
  remove: (key: StorageKey) => void;
};

@appInjectable()
export class StorageService implements IStorageService {
  private get methods(): Record<StorageType, Methods> {
    return {
      cookie: {
        get: Cookies.get,
        set: <T>(key: StorageKey, value: T, options?: StorageSetOptions) =>
          Cookies.set(key, value as unknown as string, options),
        remove: (key) => Cookies.remove(key),
      },
      localStorage: {
        get: getLocalStorage,
        set: setLocalStorage,
        remove: removeLocalStorage,
      },
    };
  }

  get = <T>(type: StorageType, key: string) => {
    return this.methods[type].get<T>(this.getStorageKey(key));
  };

  set = <T>(
    type: StorageType,
    key: string,
    value: T,
    options?: StorageSetOptions,
  ) => {
    const defaultOptions: Partial<Record<StorageType, StorageSetOptions>> = {
      cookie: { sameSite: "Lax" },
    };

    this.methods[type].set<T>(this.getStorageKey(key), value, {
      ...defaultOptions[type],
      ...options,
    });
  };

  remove: IStorageService["remove"] = (type, key) => {
    this.methods[type].remove(this.getStorageKey(key));
  };

  private getStorageKey(key: StorageKey) {
    return `${window.location.origin}_${key}`;
  }
}
