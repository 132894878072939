import React, { FC, KeyboardEvent, useEffect } from "react";
import { styles } from "./login-form.styles";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { PasswordField } from "@shared/components/password-field";
import { withStyles, WithStyles } from "@mui/styles";
import { t } from "@lingui/macro";
import { useForm } from "react-hook-form";
import {
  ILoginForm,
  LoginFieldsResolver,
  LoginFormFields,
} from "@ui/auth/login/forms/login-form/login-form.validator";
import { nameOf } from "@shared/utils/nameof";

export interface LoginFormProps extends WithStyles<typeof styles> {
  onSubmit: (formData: ILoginForm) => void;
  isLoading: boolean;
  isInvalidCredentials: boolean;
}

const LoginForm: FC<LoginFormProps> = ({
  onSubmit,
  isLoading,
  isInvalidCredentials,
}: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, errors },
  } = useForm<LoginFormFields>({ resolver: LoginFieldsResolver });

  useEffect(() => {
    if (isInvalidCredentials) {
      setError(nameOf<ILoginForm>("email"), {
        message: t`Invalid email or password`,
      });
      setError(nameOf<ILoginForm>("password"), { message: " " });
    } else {
      clearErrors();
    }
  }, [isInvalidCredentials]);

  const submitForm = (formData: ILoginForm) => {
    onSubmit(formData);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  return (
    <Box
      py={{ base: "0", sm: "8" }}
      px={{ base: "4", sm: "10" }}
      bg={{ base: "transparent", sm: "bg.surface" }}
      boxShadow={{ base: "none", sm: "md" }}
      borderRadius={{ base: "none", sm: "xl" }}
    >
      <form
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl isInvalid={!!errors.email?.message}>
              <FormLabel htmlFor="email">{t`Email`}</FormLabel>
              <Input
                {...register(nameOf<ILoginForm>("email"))}
                id="email"
                type="text"
                disabled={isLoading}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <PasswordField
              disabled={isLoading}
              label={t`Password`}
              error={errors.password?.message}
              controls={register(nameOf<ILoginForm>("password"))}
            />
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked>{t`Remember me`}</Checkbox>
          </HStack>
          <Stack spacing="6">
            <Button
              type={"submit"}
              disabled={!isValid}
              isLoading={isLoading}
            >{t`Sign in`}</Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default withStyles(styles)(LoginForm);
